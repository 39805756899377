import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Ekoo4 from "/src/components/img/ekoo/4"

export default ({data}) => (
  <Layout>
    <SEO title="Alelopatie - chemický boj druhů" />
    <h1>Alelopatie - chemický boj druhů</h1>

    <p><strong>Chemick&yacute; boj</strong> (odborněji <strong>alelopatie</strong>) je komplexn&iacute; vz&aacute;jemn&eacute; ovlivňov&aacute;n&iacute; dvou či v&iacute;ce populac&iacute; vylučovan&yacute;mi chemick&yacute;mi l&aacute;tkami. Působen&iacute; může nejčastěji konkurenčn&iacute; populaci omezovat či jinak j&iacute; &scaron;kodit, v&nbsp;někter&yacute;ch př&iacute;padech ale naopak mohou stimulovat reakce (symbionti), či je rovnou indukovat.</p>
<hr />
<h2>Alelopatika</h2>
<p><strong>Alelopatika</strong> jsou vylučovan&eacute; l&aacute;tky během alelopatie a maj&iacute; různou chemickou povahu. Nejčastěji jde o <u>silice</u>, terpenteny, fenoly a alkaloidy v&nbsp;rostlin&aacute;ch. Funguj&iacute; přev&aacute;žně jako repelenty (odpuzov&aacute;n&iacute; organismů), atraktanty (l&aacute;k&aacute;n&iacute; organismů) či juveniln&iacute; hormony (např&iacute;klad stimuluj&iacute; či potlačuj&iacute; růst jin&yacute;ch organismů).</p>
<p>Mohou b&yacute;t distribuov&aacute;na rozličn&yacute;mi způsoby, mimo jin&eacute; např&iacute;klad splachem vodn&iacute;mi sr&aacute;žkami z&nbsp;rostliny, promořov&aacute;n&iacute;m půdy kořeny, v&yacute;luhy z&nbsp;odumřel&yacute;ch č&aacute;st&iacute; rostlin (během jejich rozkladu) a vym&yacute;v&aacute;n&iacute;m ze vzduchu.</p>
<p>Kromě nekontaktn&iacute;ho alelopatick&eacute;ho působen&iacute; existuj&iacute; i různ&eacute; kontaktn&iacute; vztahy, kter&eacute; jsou doprov&aacute;zeny chemick&yacute;m působen&iacute;m z&uacute;častněn&yacute;ch organismů. Tyto se pak uplatňuj&iacute; při herbivorii, parazitismu a patogenii.</p>
<hr />
<h2>Př&iacute;klady alelopatie v př&iacute;rodě</h2>
<p>Např&iacute;klad <strong>oře&scaron;&aacute;k použ&iacute;v&aacute; takzvan&eacute; juglony</strong>, kter&eacute; jsou spl&aacute;chnuty z&nbsp;kůry a listů stromu a zamoř&iacute; povrch půdy &ndash; to je v&yacute;hodn&eacute; pro semena oře&scaron;&aacute;ku kl&iacute;č&iacute;c&iacute; př&iacute;mo na povrchu, kde konkurence vlivem juglonů vadne, či je př&iacute;mo inhibov&aacute;na. Podobně funguje trnovn&iacute;k ak&aacute;t.</p>
<Ekoo4/>
<p>Alelopatika velmi často použ&iacute;vaj&iacute; houby, např&iacute;klad různ&aacute; antibiotika na ochranu proti bakteri&iacute;m (penicilin) a řadu dal&scaron;&iacute;ch l&aacute;tek. Bakterie naopak často vytv&aacute;ř&iacute; půdn&iacute; toxiny.</p>
<p>Poln&iacute; plevel koukol poln&iacute;, kter&yacute; dnes již t&eacute;měř vymizel a je v&nbsp;dne&scaron;n&iacute; době chr&aacute;něn, použ&iacute;v&aacute; kupř&iacute;kladu hormon agrostemin, kter&yacute; stimulačně ovlivňuje růst p&scaron;enice a luskovin.</p>
<p><strong>Fytoncidy</strong> produkovan&eacute; vy&scaron;&scaron;&iacute;mi rostlinami pak toxicky působ&iacute; na mikroorganismy a parazity.</p>
<p>L&aacute;tky produkovan&eacute; živočichy, zvan&eacute; <strong>telergony</strong>, pak ovlivňuj&iacute; jin&eacute; živočichy a mohou b&yacute;t buď varovn&eacute;, obrann&eacute; či př&iacute;mo likvidačn&iacute;. Posledn&iacute; jmenovan&eacute; skupiny využ&iacute;v&aacute; americk&yacute; mot&yacute;l monarcha, kter&yacute; žere velmi jedovatou klejchu, kterou v&scaron;ak snese. Mot&yacute;l i housenka jsou t&iacute;mto extr&eacute;mně jedovat&iacute;, jsou &bdquo;promořeni&ldquo; srdečn&iacute;m glykosidem, kter&yacute; způsob&iacute; z&aacute;stavu srdce pt&aacute;kovi, kter&yacute; by snad mot&yacute;la sežral.</p>
<p>Mravenci podčeledi <em>Myrmicidae</em> jednak produkuj&iacute; kyselinu fenyloctovou, jež je baktericidn&iacute; (nutn&eacute; pro udržen&iacute; zdrav&iacute; v&nbsp;kolonii, v&nbsp;mraveni&scaron;ti totiž jinak vhodn&eacute; podm&iacute;nky pro &scaron;&iacute;řen&iacute; bakteri&iacute;), ale t&eacute;ž kyselinu beta-hydroxydekanovou (působ&iacute; jako inhibitor kl&iacute;čen&iacute; trav a růstu hub, aby kolonie nezarostla než&aacute;douc&iacute;mi druhy) a <u>kyselinu beta-indonyl-3-octovou</u>, kter&aacute; působ&iacute; jako <strong>stimul&aacute;tor růstu ž&aacute;douc&iacute;ch hub</strong>, kter&eacute; pak žerou. Mravenci obecně maj&iacute; i jinak fascinuj&iacute;c&iacute; chemii.</p>
<hr />
<h2>Chemick&yacute; boj v přírodě &ndash; využit&iacute; člověkem</h2>
<p>Alelopatik vyskytuj&iacute;c&iacute;ch se v&nbsp;př&iacute;rodě lze využ&iacute;t rozličn&yacute;mi způsoby, ať už různ&yacute;ch repelentů, atraktantů (pasti na kůrovce) či juveniln&iacute;ch hormonů.</p>
<p>Způsob kreativn&iacute;ho využit&iacute; juvenoidů (juveniln&iacute;ch hormonů) může b&yacute;t jejich použit&iacute; proti invazivn&iacute;mu druhu mravence, kter&yacute; se začal zabydlovat v&nbsp;panel&aacute;c&iacute;ch. Tyto l&aacute;tky se přidaly do cukru a mravenci si tak juvenoidy nanosili př&iacute;mo do hn&iacute;zda. Zde působily tak, že br&aacute;nily mravencům pohlavně dospět, kr&aacute;lovna tak po čase kladla neoplozen&aacute; vaj&iacute;čka a hn&iacute;zdo postupně vyhynulo.</p>
<p>Potenci&aacute;ln&iacute; je i využit&iacute; detekce někter&yacute;ch druhů brouků, byť jen jedin&eacute;ho listu housenkou na kilometrovou vzd&aacute;lenost (detekce kvůli potravě). Tato detekce prob&iacute;h&aacute; na molekul&aacute;rn&iacute; &uacute;rovni a mohla by b&yacute;t využita pro brzk&eacute; odhalen&iacute; napaden&iacute; kultur &scaron;kůdcem.</p>

    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Alelopatie - chemický boj druhů</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/zivotni-strategie/"><button className="inv">&larr; Životní a populační strategie rostlin</button></Link>
    <Link to="/ekologie-zivotni-prostredi/ph-reakce-prostredi/"><button className="inv">pH reakce prostředí &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
